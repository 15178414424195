import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { Form } from 'form'

import FormField from '../forms/formField'
import Select from '../select'
import fields from './fields'
import schema from './schema'
import sections from './sections'

import {
  FieldsContainer,
  Container,
  FormContainer,
  Title,
  SectionContainer,
  Section,
  SectionTitle,
  SectionDescription,
  FormButton
} from './styles'

const Profile = ({
  categories,
  isLoading,
  user,
  updateUser,
  updateUserPassword,
  getUser,
  getCountries,
  countries
}) => {
  const [formApi, setFormApi] = useState()
  const [activeSection, setActiveSection] = useState('basic')

  useEffect(() => {
    if (!user.id) {
      getUser()
    }
    getCountries()
  }, [])

  useEffect(() => {
    if (formApi) {
      formApi.setFields(getInitialValues(user))
    }
  }, user)

  useEffect(() => {
    if (formApi) {
      formApi.resetFields()
    }
  }, [activeSection])

  useEffect(() => {
    if (activeSection === 'password' && !isLoading && formApi) {
      formApi.resetFields()
    }
  }, [isLoading])

  const handleSubmit = () => {
    if (formApi.validate()) {
      const values = formApi.getTouchedValues()

      if (activeSection === 'password') {
        const { currentPassword, password } = values

        updateUserPassword({
          oldPassword: currentPassword,
          newPassword: password
        })
      } else {
        if (values.language) {
          values.language = [values.language]
        }

        updateUser(values)
      }
    }
  }

  const getInitialValues = ({ language = [], country, ...rest } = {}) => {
    return {
      ...rest,
      language: language ? language[0] : '',
      countryCode: country ? country.countryCode : ''
    }
  }

  return (
    <Container>
      <SectionContainer>
        <Title>ملف تعريفي للمستخدم</Title>

        {sections.map(section => (
          <Section
            key={section.key}
            onClick={() => setActiveSection(section.key)}
            active={activeSection === section.key}
          >
            <SectionTitle>{section.title}</SectionTitle>
            <SectionDescription>{section.description}</SectionDescription>
          </Section>
        ))}
      </SectionContainer>

      <FormContainer>
        <Form
          formApi={setFormApi}
          schema={schema[activeSection]}
          initialValues={getInitialValues(user)}
          key={`${activeSection}_form`}
        >
          <FieldsContainer>
            <div className="activeSection">
              <Title style={{ margin: '-15px 0 20px 0' }}>
                ملف تعريفي للمستخدم
              </Title>

              <Select
                value={activeSection}
                onChange={setActiveSection}
                items={sections.map(({ key, title }) => ({
                  value: key,
                  label: title
                }))}
              />
            </div>

            {fields({ countries, categories })[activeSection].map(FormField)}
          </FieldsContainer>

          <FormButton disabled={isLoading}>
            <button onClick={handleSubmit}>
              {sections.find(({ key }) => key === activeSection)?.submit}

              {isLoading && (
                <div className="lds-ring">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              )}
            </button>
          </FormButton>
        </Form>
      </FormContainer>
    </Container>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    updateUser: payload => {
      dispatch({ type: 'UPDATE_USER_REQUEST', payload })
    },
    updateUserPassword: payload => {
      dispatch({ type: 'UPDATE_USER_PASSWORD_REQUEST', payload })
    },
    getUser: () => {
      dispatch({ type: 'USER_REQUEST' })
    },
    getCountries: () => {
      dispatch({ type: 'GET_COUNTRIES' })
    }
  }
}

function mapStateToProps(state) {
  const { categories, user, countries } = state

  return {
    categories: categories.data.filter(category => category.showInMenu),
    ...user,
    countries: countries.countries
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
