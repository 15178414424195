import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const FieldsContainer = styled.div`
  display: grid;
  grid-gap: 30px 0;
  max-width: 510px;

  @media ${device.s} {
    max-width: 375px;
  }
`

export const Container = styled.div`
  height: calc(100vh - 80px);
  display: flex;
  margin-top: -39px;

  @media ${device.s} {
    height: 100vh;
    margin-top: -60px;
  }
`

export const FormContainer = styled.div`
  width: 100%;
  padding-right: 50px;
  padding-top: 108px;
  box-sizing: border-box;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  overflow: auto;
  padding-bottom: 100px;
  padding-left: 30px;

  .activeSection {
    display: none;
  }

  @media ${device.s} {
    padding-right: 30px;
    padding-left: 30px;

    .activeSection {
      display: block;
    }
  }
`

export const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  color: #424242;
  margin-bottom: 35px;
  margin-top: 50px;
`

export const SectionContainer = styled.div`
  max-width: 320px;
  width: 100%;
  padding-right: 105px;
  padding-left: 30px;

  @media ${device.s} {
    display: none;
  }
`

export const Section = styled.div`
  padding: 20px 30px 30px 30px;
  margin-bottom: 15px;
  cursor: pointer;

  ${({ active }) =>
    active &&
    `
    background-color: #fff;  
    border: 1px solid #e3e3e3;
  `}
`

export const SectionTitle = styled.div`
  color: #424242;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 5px;
`

export const SectionDescription = styled.div`
  font-size: 15px;
  color: #909090;
`

export const FormButton = styled.div`
  max-width: 510px;
  margin-bottom: 30px;

  button {
    background: #52c368;
    color: #fff;
    border: none;
    border-radius: 0 10px;
    padding: 13px 22px;
    cursor: pointer;
    transition: background 0.1s ease-in;
    font-weight: bold;
    font-size: 1em;
    margin-top: 50px;
    float: left;
    outline: none !important;

    &:hover {
      background: #44b059;
    }
  }
`
