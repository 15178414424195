export default [
  {
    key: "basic",
    title: "المعلومات الأساسية",
    description:
      "الاسم, البريد الإلكتروني, الموقع, اللغة المفضلة, الفئة العمرية",
    submit: "إرسال",
  },
  {
    key: "password",
    title: "إدارة كلمة المرور",
    description: "إدارة كلمة المرور الخاصة بك",
    submit: "إرسال",
  },
  {
    key: "advanced",
    title: "الملف الشخصي الموسع",
    description: "حسّن تجربتك مع الشرق بإعطائنا مزيد من المعلومات.",
    submit: "إرسال",
  },
]
