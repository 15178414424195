import React, { useEffect } from 'react'
import { graphql } from 'gatsby'
import { Redirect } from '@reach/router'

import Layout from '../components/layout'
import Profile from '../components/profile'
import { addGoogleAnalyticsPageData } from '../utils/googleAnalyticsEvents'

const ProfilePage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  // profile page content is being shown to non logged-inuser before actually redirected to homepage
  if (
    typeof window !== 'undefined' &&
    localStorage.getItem('userType') !== 'user'
  ) {
    return <Redirect noThrow to={`/`} />
  }

  useEffect(() => {
    addGoogleAnalyticsPageData()
  }, [])

  return (
    <Layout
      location={location}
      title={siteTitle}
      lang={'ar'}
      maxWidth="auto"
      padding="0px"
    >
      <Profile />
    </Layout>
  )
}

export default ProfilePage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
