import languages from "../../constants/languages"

export default ({ countries, categories }) => {
  categories = categories.map(cat => ({ label: cat.ar, value: cat.id }))

  return {
    basic: [
      { name: "firstName", label: "الاسم الأول", required: true },
      { name: "lastName", label: "أسم العائلة", required: true },
      {
        name: "email",
        label: "البريد الإلكتروني",
        required: true,
        disabled: true,
      },
      {
        name: "gender",
        label: "الجنس",
        required: true,
        component: "radio",
        items: [
          { value: "male", label: "ذكر" },
          { value: "female", label: "أنثى" },
        ],
      },
      {
        name: "ageRange",
        label: "العمر",
        component: "select",
        items: [
          { label: "10-20", value: "10-20" },
          { label: "20-30", value: "20-30" },
          { label: "30-40", value: "30-40" },
          { label: "40-50", value: "40-50" },
          { label: "50-60", value: "50-60" },
          { label: "60-70", value: "60-70" },
          { label: "70-80", value: "70-80" },
          { label: "80-90", value: "80-90" },
          { label: "90-100", value: "90-100" },
        ],
        required: true,
      },
      {
        name: "loveCategories",
        label: "موضوعات الاهتمام",
        required: true,
        items: categories,
        component: "checkboxGroup",
      },
    ],
    password: [
      {
        name: "currentPassword",
        label: "كلمة المرور الحالي",
        required: true,
        type: "password",
      },
      {
        name: "password",
        label: "كلمة السر الجديدة",
        required: true,
        type: "password",
      },
      {
        name: "confirmPassword",
        label: "تأكيد كلمة المرور الجديدة",
        required: true,
        type: "password",
      },
    ],
    advanced: [
      {
        name: "countryCode",
        label: "الجنسية",
        items: countries,
        component: "select",
        searchable: true,
      },
      {
        name: "maritalStatus",
        label: "الحالة الاجتماعية",
        required: true,
        component: "radio",
        items: [
          { value: "married", label: "متزوج" },
          { value: "single", label: "أعزب" },
          { value: "divorced", label: "مطلق" },
        ],
      },
      {
        name: "numberOfChildren",
        label: "عدد الأطفال",
        component: "select",
        items: [
          { value: 0, label: "0" },
          { value: 1, label: "1" },
          { value: 2, label: "2" },
          { value: 3, label: "3" },
          { value: 4, label: "4" },
        ],
      },
      {
        name: "monthlyIncome",
        label: "الدخل الشهري التقريبي",
        component: "select",
        items: [
          { value: "100-1000", label: "100-1000" },
          { value: "$1000-$2000", label: "$1000-$2000" },
          { value: "$2000-$5000", label: "$2000-$5000" },
          { value: "more than $5000", label: "more than $5000" },
        ],
      },
      {
        name: "occupation",
        label: "الوظيفة م مجال العمل",
        component: "input",
      },
      {
        name: "language",
        label: "اللغات",
        component: "select",
        items: languages,
        searchable: true,
      },
    ],
  }
}
