import { string, object, array, ref } from "yup"

const basicSchema = object().shape({
  firstName: string().required("الاسم الأول مطلوب"),
  lastName: string().required("الاسم الأخير مطلوب"),
  email: string()
    .required("البريد الإلكتروني مطلوب")
    .email("يجب أن يكون عنوان البريد الإلكتروني صالحاً"),
  loveCategories: array().required("اختر فئة واحدة على الأقل"),
  ageRange: string().required("إدخال العمر مطلوب"),
  gender: string().required("إدخال الجنس مطلوب"),
})

const advancedSchema = object().shape({
  countryCode: string().nullable(),
  maritalStatus: string().nullable(),
  numberOfChildren: string().nullable(),
  monthlyIncome: string().nullable(),
  language: string().nullable(),
  occupation: string().nullable(),
})

const passwordSchema = object().shape({
  currentPassword: string()
    .required("كلمة المرور مطلوبة")
    .min(8, "يجب أن تتضمن كلمة المرور 8 أحرف على الأقل"),
  password: string()
    .required("كلمة المرور الجديدة مطلوبة")
    .min(8, "يجب أن تتضمن كلمة المرور 8 أحرف على الأقل"),
  confirmPassword: string()
    .required("تأكيد كلمة المرور مطلوب")
    .oneOf([ref("password"), null], "كلمات المرور غير متطابقة")
    .min(8, "يجب أن تتضمن كلمة المرور 8 أحرف على الأقل"),
})

export default {
  basic: basicSchema,
  advanced: advancedSchema,
  password: passwordSchema,
}
